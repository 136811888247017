/* Ensure full-page fit */
.order-visuals-container {
    min-height: 100vh;
    background-color: #1a1a1a;
    color: white;
    padding: 6rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Title Styling */
.title {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1.5rem;
}

/* Stats Section */
.stats-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
    margin-bottom: 2rem;
}

.stat-card {
    background-color: #2a2a2a;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
    text-align: center;
    min-width: 200px;
    flex: 1;
}

.stat-card h2 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
}

.stat-card p {
    font-size: 1.5rem;
    font-weight: bold;
}

/* Chart Section */
.charts-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    width: 100%;
}

.chart-box {
    background-color: #2a2a2a;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
    width: 100%;
    max-width: 500px;
}

/* Table Section */
.table-title {
    font-size: 1.8rem;
    text-align: center;
    margin: 2rem 0 1rem;
}

.table-container {
    width: 100%;
    overflow-x: auto;
}

.orders-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #2a2a2a;
    border-radius: 8px;
    overflow: hidden;
}

.orders-table th, .orders-table td {
    border: 1px solid #444;
    padding: 10px;
    text-align: center;
}

.orders-table th {
    background-color: #333;
}

.orders-table tbody tr:hover {
    background-color: #444;
}

.no-orders {
    text-align: center;
    padding: 10px;
}

/* Button Styling */
.button-container {
    margin-top: 2rem;
}

.back-button {
    padding: 12px 24px;
    font-size: 1rem;
    font-weight: bold;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
}

.back-button:hover {
    background-color: #0056b3;
}
