/* Container for centering the contact form */
.contact-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 50vh; /* Adjust height for vertical centering */
  padding: 20px;
  text-align: center;
}

/* Styling for the contact form */
.contact-form {
  width: 100%;
  max-width: 500px; /* Limit form width */
  background: white; /* Form background color */
  padding: 20px;
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Heading styling */
.contact-form h2 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Input fields styling */
.contact-input,
.contact-textarea {
  font-size: 16px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  width: 100%;
  box-sizing: border-box;
}

.contact-input:focus,
.contact-textarea:focus {
  outline: none;
  border-color: #007bff; /* Highlight border on focus */
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.5); /* Add shadow for focus */
}

/* Button styling */
.contact-button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.contact-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}
