.cart-page {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.cart-items {
  list-style: none;
  padding: 0;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.cart-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cart-controls input {
  width: 50px;
  padding: 5px;
  text-align: center;
}

.cart-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.clear-cart-button,
.proceed-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.clear-cart-button {
  background-color: #dc3545;
  color: white;
}

.clear-cart-button:hover {
  background-color: #b02a37;
}

.proceed-button {
  background-color: #007bff;
  color: white;
}

.proceed-button:hover {
  background-color: #0056b3;
}
