/* ==========================
   🌑 DARK THEME STYLES 
   ========================== */

/* Dark background with white text */
.events-container {
    background-color: #121212; /* Dark background */
    color: white; /* White text */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 20px 20px; /* ✅ Fix for header overlap */
}

/* Title */
.events-heading {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
}

/* Table container */
.events-table-container {
    width: 100%;
    max-width: 1200px;
    overflow-x: auto; /* ✅ Allows horizontal scrolling on small screens */
}

/* Main Event Table */
.events-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

/* Table header */
.events-table th, .events-table td {
    border: 1px solid #444;
    padding: 12px;
    text-align: left;
    vertical-align: top;
}

.events-table th {
    background-color: #1e1e1e;
    color: #f1f1f1;
}

/* Alternating row colors */
.events-table tr:nth-child(even) {
    background-color: #222;
}

.events-table tr:nth-child(odd) {
    background-color: #2a2a2a;
}

/* Style for event images */
.event-image {
    width: 80px;  /* ✅ Adjust size */
    height: auto;
    border-radius: 5px;
    object-fit: cover;
}

/* Description Styling */
.event-description {
    max-width: 300px;
    word-wrap: break-word;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Ticket Table Styling */
.inner-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #181818; /* Darker background for inner table */
    margin-top: 5px;
}

.inner-table th, .inner-table td {
    border: 1px solid #333;
    padding: 8px;
    text-align: left;
    font-size: 14px; /* Smaller font for nested table */
}

.inner-table th {
    background-color: #252525;
    color: #fff;
}

/* ==========================
   ➕ ADD EVENT BUTTON
   ========================== */
.add-event-button {
    display: block;
    margin: 20px auto;
    padding: 12px 20px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    background-color: #0066ff; /* 🔵 Blue button */
    color: white;
    border: none;
    border-radius: 5px;
    transition: 0.3s;
}

.add-event-button:hover {
    background-color: #004dcc; /* Darker blue on hover */
}

/* ==========================
   🔳 MODAL STYLES
   ========================== */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    z-index: 1000;
    overflow-y: auto; /* ✅ Allows scrolling if modal exceeds viewport */
    padding: 20px; /* ✅ Adds spacing to prevent cutoff */
}


.modal-content {
    background: #1e1e1e;
    padding: 25px;
    border-radius: 10px;
    width: 400px;
    max-height: 80vh; /* ✅ Keeps modal within 80% of the viewport height */
    overflow-y: auto; /* ✅ Allows scrolling if content exceeds height */
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.2);
    animation: fadeIn 0.3s ease-in-out;
    text-align: left;
    display: flex;
    flex-direction: column;
}




/* Smooth fade-in animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Input fields inside modal */
.event-form input,
.event-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #333;
    border-radius: 5px;
    background: #252525;
    color: white;
    font-size: 16px;
}

/* Ticket Input Styling */
.ticket-inputs {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

/* Add Ticket & Remove Ticket buttons */
.ticket-inputs button {
    padding: 8px 12px;
    background-color: red;
    border: none;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

.ticket-inputs button:hover {
    background-color: darkred;
}

/* Modal Buttons */
.modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.modal-buttons button {
    padding: 10px;
    border: none;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    width: 48%;
}

.modal-buttons button:first-child {
    background-color: #28a745; /* ✅ Green for Add Event */
    color: white;
}

.modal-buttons button:first-child:hover {
    background-color: #218838;
}

.modal-buttons button:last-child {
    background-color: #dc3545; /* ❌ Red for Cancel */
    color: white;
}

.modal-buttons button:last-child:hover {
    background-color: #c82333;
}

/* ==========================
   📱 RESPONSIVE DESIGN 
   ========================== */
@media (max-width: 768px) {
    .events-heading {
        font-size: 1.8rem; /* Smaller title for mobile */
    }

    .events-container {
        padding: 80px 10px 20px; /* ✅ Adjusted padding for mobile */
    }

    .events-table-container {
        overflow-x: auto;
    }

    .events-table th, .events-table td {
        padding: 8px;
        font-size: 14px; /* Smaller font for better readability */
    }

    .inner-table th, .inner-table td {
        font-size: 12px; /* Even smaller font for mobile */
    }

    .event-image {
        width: 50px; /* Smaller image on mobile */
    }

    .modal-content {
        width: 90%;
        max-height: 85vh; /* ✅ Slightly larger for mobile */
        padding: 20px;
    }
}

.delete-event-button {
    background-color: #dc3545; /* 🔴 Red delete button */
    color: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.3s;
    font-size: 14px;
}

.delete-event-button:hover {
    background-color: #c82333; /* Darker red on hover */
}

.edit-event-button {
    background-color: #007bff; /* 🔵 Blue button */
    color: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.3s;
    font-size: 14px;
    margin-right: 5px;
}

.edit-event-button:hover {
    background-color: #0056b3;
}
.edit-event-image {
    width: 100%;
    max-height: 200px;
    border-radius: 5px;
    object-fit: cover;
    margin-bottom: 10px;
}

.add-ticket-button {
    display: block;
    background-color: #28a745;
    color: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
    font-size: 14px;
}

.add-ticket-button:hover {
    background-color: #218838;
}

.remove-ticket-button {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    margin-left: 10px;
}

.remove-ticket-button:hover {
    background-color: #c82333;
}
