.footer {
    width: 100%;
    text-align: center;
    padding: 10px 0;
    background-color: transparent; /* Transparent background */
    color: #555; /* Subtle text color */
    font-size: 14px;
    position: relative;
    bottom: 0;
  }
  