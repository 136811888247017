.orders-container {
  padding: 80px 20px 50px; /* Increased top padding to push content below header */
  background-color: #121212;
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.orders-heading {
  text-align: center;
  margin-bottom: 20px;
}

.orders-table-container {
  overflow-x: auto; /* Enables horizontal scroll on small screens */
  margin: 0 10px;
}

.filter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 20px;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  gap: 10px; /* Add spacing between items */
}

.order-count {
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  background-color: #007bff;
  padding: 10px 20px;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 5px; /* Space between total orders and total amount */
}

.filter-container label {
  font-size: 14px;
  color: white;
}

.filter-container select {
  padding: 5px 10px;
  background-color: #1e1e1e;
  color: white;
  border: 1px solid #007bff;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
}

.filter-container select:hover {
  background-color: #333;
}

.orders-table-container {
  overflow-x: auto; /* Enable horizontal scrolling */
  margin: 0 10px; /* Add slight margin for better mobile view */
}

.orders-table {
  width: 100%;
  min-width: 600px; /* Ensure the table doesn't shrink too much */
  border-collapse: collapse;
  background-color: #1e1e1e;
  color: white;
  border-radius: 10px;
  overflow: hidden;
}

.orders-table th,
.orders-table td {
  border: 1px solid #333;
  padding: 10px;
  text-align: left;
}

.orders-table th {
  background-color: #007bff;
  color: white;
}

.orders-table tr:nth-child(even) {
  background-color: #222;
}

.orders-table tr:hover {
  background-color: #333;
}

.loading {
  text-align: center;
  font-size: 18px;
  margin-top: 50px;
}

.event-filter {
  background-color: #1e1e1e;
  color: white;
  border: 1px solid #007bff;
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  min-width: 150px; /* Ensure a consistent width */
}

/* Hover Effect */
.event-filter:hover {
  background-color: #333;
}

.total-amount {
  font-size: 16px;
  background-color: #0056b3;
  padding: 6px 14px;
  border-radius: 6px;
  color: #ffffff;
  margin-top: 5px;
}

.total-tickets {
  font-size: 16px;
  background-color: #004080;
  padding: 6px 14px;
  border-radius: 6px;
  color: #ffffff;
  margin-top: 5px;
}

@media (max-width: 600px) {
  .orders-heading {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .order-count {
    font-size: 16px;
    padding: 6px 12px;
  }

  .event-filter {
    width: 100%;
    max-width: 200px;
    margin-left: auto; /* Align to the right */
  }

  .orders-table-container {
    margin: 0;
  }

  .orders-container {
    padding: 60px 10px 50px; /* Reduce padding for mobile */
  }
}
.resend-email-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.resend-email-btn:hover {
  background-color: #0056b3;
}
.download-csv-btn {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.download-csv-btn:hover {
  background-color: #218838;
}

.editable-email-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.editable-email {
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.email-display-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.edit-email-btn,
.save-email-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: #007bff;
  font-size: 16px;
}

.edit-email-btn:hover,
.save-email-btn:hover {
  color: #0056b3;
}

.edit-email-btn:disabled,
.save-email-btn:disabled {
  color: #ccc;
  cursor: not-allowed;
}
