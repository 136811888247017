.main-section {
  background-image: url('../assets/BKGNDBCT.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}

.main-content h1 {
  font-size: 48px;
  margin-bottom: 20px;
  font-weight: bold;
}

.main-content p {
  font-size: 18px;
  margin-bottom: 30px;
}

.buttons button {
  margin: 0 10px;
}
