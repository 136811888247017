.faq-section {
    padding: 40px 20px;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .faq-section h2 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }
  
  .faq-container {
    max-width: 600px; /* Set the maximum width for the FAQ section */
    margin: 0 auto; /* Center the container */
    text-align: left; /* Ensure text aligns correctly */
  }
  
  .ant-collapse-header {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  
  .ant-collapse-content {
    text-align: left;
    padding: 10px 20px;
    font-size: 14px;
    color: #555;
  }
  
  .ant-collapse-item {
    border: 1px solid #ddd;
    margin-bottom: 10px;
  }
  
  .ant-collapse-item-active {
    border: 1px solid #007bff;
  }
  