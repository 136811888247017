.payment-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #121212; /* Dark background */
  padding: 20px 0 60px; /* Added padding at the bottom to avoid overlap with the footer */
  min-height: 100vh; /* Ensure footer stays at the bottom of the page */
  box-sizing: border-box;
  position: relative;
}

.payment-container {
  background-color: white; /* Form background */
  color: black; /* Text color inside form */
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for better visibility */
  width: 100%;
  max-width: 900px; /* Make the dialog wide enough to look modern */
  box-sizing: border-box;
  margin-top: 100px; /* Ensure the dialog is always below the navbar */
}

.payment-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%; /* Span the entire width */
  background-color: #1f1f1f; /* Dark footer background */
  color: white;
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
  z-index: 10; /* Ensure the footer stays on top of content */
}

.payment-container h3 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.payment-container input {
  padding: 12px;
  font-size: 16px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 6px;
  margin-bottom: 20px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.payment-container input:focus {
  border-color: #007bff;
  box-shadow: 0 0 6px rgba(0, 123, 255, 0.4);
  outline: none;
}

.payment-container .card-element-container {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  margin-bottom: 20px;
}

.payment-container .pay-now-button {
  background-color: #007bff;
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 15px;
  border: none;
  border-radius: 6px;
  width: 100%; /* Full width button */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.payment-container .pay-now-button:hover {
  background-color: #0056b3;
  transform: scale(1.02);
}

.payment-container .pay-now-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.payment-container .payment-message {
  margin-top: 20px;
  font-size: 16px;
  color: green;
  text-align: center;
}

.admin-footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #000;
  color: white;
  text-align: center;
  padding: 10px;
  font-size: 14px;
  z-index: 100; /* Ensure the footer stays above all content */
}
