.features-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; /* Allows wrapping on smaller screens */
    padding: 40px 20px;
    background-color: #f9f9f9; /* Light background color */
    gap: 20px; /* Space between items */
  }
  
  .feature-item {
    text-align: center;
    max-width: 200px; /* Adjust width for consistent sizing */
    flex: 1 1 150px; /* Flexible layout for responsiveness */
  }
  
  .feature-icon {
    font-size: 36px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .feature-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
    color: #000;
  }
  
  .feature-item p {
    font-size: 14px;
    color: #666;
  }
  
  .divider {
    width: 1px;
    background-color: #ddd;
    height: 80px;
  }
  
  @media (max-width: 768px) {
    .features-section {
      flex-direction: column; /* Stack items on smaller screens */
    }
  
    .divider {
      display: none; /* Hide dividers on smaller screens */
    }
  
    .feature-item {
      margin-bottom: 20px; /* Add spacing between stacked items */
    }
  }
  