.testimonial-section {
    padding: 40px 20px;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .testimonial {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .testimonial-text {
    font-size: 18px;
    font-style: italic;
    color: #555;
    margin-bottom: 20px;
  }
  
  .testimonial-author {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .author-image {
    width: 50px;
    height: 50px;
    background-color: #ccc;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .person-icon {
    font-size: 24px;
    color: #fff;
  }
  
  .author-name {
    font-weight: bold;
    font-size: 16px;
  }
  