/* ✅ Dark background for the scanner page */
.scanner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #121212; /* Dark background */
    color: white; /* White text for contrast */
    text-align: center;
  }
  
  /* ✅ QR code scanner box */
  #reader {
    width: 300px;
    height: 300px;
    border: 2px solid white;
    border-radius: 10px;
    margin-top: 20px;
  }
  
  /* ✅ Status message styling */
  .status-message {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
  }
  
  /* ✅ Responsive styles */
  @media (max-width: 600px) {
    #reader {
      width: 90%;
      height: auto;
    }
  }

  .dialog-box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    color: black;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .dialog-box button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .dialog-box button:hover {
    background-color: #0056b3;
  }
  
  