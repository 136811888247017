.event-detail-container {
  background-color: #121212;
  min-height: 100vh;
  padding: 100px 20px 20px; /* Extra top padding for header */
  color: white;
}

.event-detail-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px;
  background-color: #1e1e1e;
  border-radius: 8px;
}

.event-detail-image {
  width: 100%;
  max-height: 500px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 30px;
}

.event-detail-location {
  display: flex;
  align-items: center;
  margin: 20px 0;
  font-size: 18px;
}

.location-icon {
  margin-right: 10px;
  color: #ff4d4f;
}

.event-detail-datetime {
  font-size: 16px;
  margin-bottom: 20px;
  color: #aaa;
}

.event-detail-description {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 30px;
}

/* Main page container */
.event-page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #121212; /* Dark background matching admin */
}

/* Footer styling to match admin dashboard */
.footer {
  width: 100%;
  text-align: center;
  padding: 10px 0;
  background-color: transparent; /* Transparent background */
  color: #555; /* Subdued text color */
  font-size: 14px;
}
