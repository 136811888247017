.events-section {
  padding: 40px 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.events-section h2 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 10px;
}

.section-divider {
  width: 80px;
  height: 4px;
  background-color: #007bff;
  border: none;
  margin: 0 auto 30px auto;
}

.events-grid {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.event-card {
  width: 300px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.event-card:hover {
  transform: scale(1.05);
}

.event-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

.event-card h3 {
  font-size: 20px;
  margin: 16px 0;
}

.event-location {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  color: #555;
  font-size: 14px;
}

.location-icon {
  font-size: 16px;
  margin-right: 5px;
}

.event-modal-image {
  width: 100%;
  height: auto;
  margin-bottom: 16px;
}

.event-modal-location {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0;
  color: #555;
  font-size: 16px;
}

.modal-location-icon {
  font-size: 20px;
  margin-right: 8px;
}

@media (max-width: 768px) {
  .event-card {
    width: 100%;
  }

  .events-grid {
    flex-direction: column;
    gap: 20px;
  }
}

.event-location {
  display: flex;
  align-items: center;
  margin: 10px 0;
  font-size: 16px;
  color: #333;
  font-weight: bold;
}

.location-icon {
  color: #d9534f;  /* Red color for the location pin */
  margin-right: 8px;
  font-size: 18px;
}
