.terms-and-conditions-page {
  background-color: black; /* Black background */
  color: white; /* White text */
  min-height: 100vh; /* Full page height */
  padding-top: 80px; /* Space for the header */
}

.terms-and-conditions-content {
  max-width: 800px; /* Limit content width */
  margin: 0 auto; /* Center content */
  padding: 20px;
}

.terms-and-conditions-content h1 {
  font-size: 32px;
  margin-bottom: 20px;
}

.terms-and-conditions-content h2 {
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.terms-and-conditions-content p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 15px;
}
