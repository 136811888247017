/* ✅ Full-page layout with dark background */
.admin-dashboard-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full height for footer positioning */
  background-color: #121212; /* ✅ Dark background */
  color: white; /* ✅ White text for contrast */
}

/* ✅ Content Area */
.admin-content {
  flex: 1; /* Pushes footer to the bottom */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center buttons vertically */
  align-items: center; /* Center buttons horizontally */
  gap: 20px; /* Space between buttons */
}

/* ✅ Button Styling (matching the original style) */
.admin-button {
  padding: 15px 30px;
  background-color: #007bff; /* ✅ Blue button */
  color: white;
  border: none;
  border-radius: 8px; /* ✅ Rounded corners */
  cursor: pointer;
  font-size: 18px; /* ✅ Larger font for better visibility */
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease; /* ✅ Smooth hover effect */
}

/* ✅ Hover Effect */
.admin-button:hover {
  background-color: #0056b3; /* ✅ Darker blue on hover */
  transform: scale(1.05); /* ✅ Slight zoom effect on hover */
}

/* ✅ Footer (already styled in Footer.css) */
.footer {
  width: 100%;
  text-align: center;
  padding: 10px 0;
  background-color: transparent;
  color: #555;
  font-size: 14px;
}
.logout-button {
  background-color: #ff6b6b; /* Red color for logout button */
}

.logout-button:hover {
  background-color: #ff4c4c; /* Darker red on hover */
}
