.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  min-height: calc(100vh - 50px); /* Adjust for the footer height */
  box-sizing: border-box;
}

.checkout-wrapper {
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 900px; /* Ensure consistency across sections */
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.customer-info,
.cart-summary {
  flex: 1;
}

.customer-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.customer-info h3,
.cart-summary h3 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.cart-summary {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-sizing: border-box;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.cart-item span {
  font-size: 16px;
  color: #333;
}

.cart-summary .total {
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
