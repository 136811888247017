.order-confirmation {
  background-color: #121212;
  color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-top: 80px;
  font-family: Arial, sans-serif;
  box-sizing: border-box;
}

/* Add specific styling for mobile screens */
@media (max-width: 768px) {
  .order-confirmation {
    padding-top: 100px; /* Increase top padding for mobile screens */
  }

  h1 {
    font-size: 20px; /* Adjust font size for smaller screens */
    text-align: center; /* Center-align the text on mobile */
    margin-bottom: 20px; /* Add spacing below the heading */
  }

  .order-summary {
    width: 90%; /* Reduce width to fit smaller screens */
    padding: 15px; /* Adjust padding for better layout on mobile */
  }

  .ticket-card {
    width: 90%; /* Ensure ticket cards don't overflow on mobile */
  }
}

.order-summary {
  background-color: #1f1f1f; /* Slightly lighter dark background */
  color: #ffffff;
  text-align: left;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #333;
  border-radius: 6px;
  width: 80%;
  max-width: 600px;
}

.price-breakdown {
  margin-top: 10px;
  background-color: inherit; /* Match the order summary background */
  color: inherit; /* Match text color */
  padding: 0;
  border: none; /* Seamlessly blend with the order summary */
}

.price-breakdown h3 {
  font-size: 16px;
  margin-bottom: 8px;
  color: inherit;
}

.price-table {
  width: 100%;
  border-collapse: collapse; /* Ensure table fits seamlessly */
}

.price-table td {
  padding: 5px 0;
  font-size: 14px;
  color: inherit;
}

.price-total {
  margin-top: 10px;
  font-size: 14px;
  text-align: right;
  color: inherit;
  font-weight: bold;
}

.tickets-section {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.ticket-card {
  background-color: #1f1f1f; /* Match the dark theme of the summary */
  color: #ffffff; /* White text for contrast */
  border: 1px solid #333; /* Subtle border to match theme */
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Slight shadow for a floating effect */
  padding: 15px;
  width: 220px;
  text-align: center;
}

.qr-code-container {
  margin-bottom: 10px;
  background-color: #ffffff; /* White box for QR code contrast */
  padding: 10px;
  border-radius: 6px;
}

.qr-code-with-logo {
  position: relative;
  display: inline-block;
}

.qr-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px; /* Adjust size to fit your QR code */
  height: 30px;
}

.ticket-info {
  margin-top: 10px;
}

.ticket-info p {
  margin: 5px 0;
  font-size: 14px;
  color: #cccccc; /* Light grey text */
}

.print-button {
  background-color: #28a745; /* ✅ Green color */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 20px;
}

.print-button:hover {
  background-color: #218838; /* ✅ Darker green on hover */
}

.print-icon {
  font-size: 18px;
}

@media print {
  body, .order-confirmation {
    background: white !important;
    color: black !important;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    margin: 0;
    padding: 0;
  }

  /* ✅ Add space at the top to prevent overlap */
  .order-confirmation {
    padding-top: 150px !important;  /* Push content down for print */
  }

  .print-button, .print-icon {
    display: none !important;         /* ✅ Hide the print button and icon */
  }

  .order-summary,
  .ticket-card,
  .qr-code-container {
    background: white !important;     /* ✅ Ensure key sections have white background */
    color: black !important;          /* ✅ Black text for clear print */
    border: 1px solid #000 !important;/* ✅ Add borders for visibility */
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .price-table td {
    color: black !important;          /* ✅ Ensure table text is black */
  }

  .ticket-card {
    background: white !important;     /* ✅ White background */
    color: black !important;          /* ✅ Black text for clear visibility */
    border: 1px solid #000 !important;
    page-break-inside: avoid;         /* ✅ Prevent splitting across pages */
    margin-bottom: 10px;              /* ✅ Add spacing between cards */
  }

  .ticket-info p {
    color: black !important;          /* ✅ Make ticket details black */
    font-weight: bold;                /* ✅ Bold text for better clarity */
  }
}


.content-wrapper {
  flex: 1;  /* This allows the content to expand and push the footer down */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.download-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 6px 12px;
  font-size: 14px;
  border-radius: 20px;  /* Rounded corners for a sleek look */
  cursor: pointer;
  margin: 10px auto;    /* Center the button */
  display: flex;
  align-items: center;
  gap: 6px;             /* Space between text and icon */
  transition: background-color 0.3s ease;
  justify-content: center;
  width: 120px;         /* Skinny button width */
}

.download-button:hover {
  background-color: #0056b3;
}

.download-icon {
  font-size: 16px;
}

.event-location {
  font-size: 14px;
  color: #cccccc; /* Light grey for subtle appearance */
  margin: 4px 0;  /* Adds spacing between event name and ticket type */
  text-align: center; /* Center-align the location */
}
