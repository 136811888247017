/* Dark background for the entire page */
.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center vertically */
  min-height: 100vh; /* Full viewport height */
  background-color: #121212; /* Dark background */
  color: #ffffff; /* White text */
  padding: 20px;
}

/* Centered login container */
.login-container {
  background-color: #1e1e1e; /* Slightly lighter dark background */
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

/* Login form styling */
.login-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Input fields styling */
.login-input {
  padding: 10px;
  border: 1px solid #333;
  border-radius: 5px;
  background-color: #333;
  color: #ffffff;
  font-size: 16px;
  outline: none;
}

.login-input::placeholder {
  color: #aaa;
}

.login-input:focus {
  border-color: #646cff;
}

/* Login button styling */
.login-button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #646cff;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #535bf2;
}

/* Error message styling */
.error-message {
  color: #ff6b6b;
  margin-bottom: 20px;
}
